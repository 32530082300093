import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Crowdfund} from "../../../models/Crowdfund.model";

@Injectable({
  providedIn: 'root'
})
export class CrowdfundService {
  private apiUrl : string = environment.apiUrl;

  constructor(private httpClient: HttpClient) {

  }

  public getAllCrowdfunds(): Observable<Crowdfund[]> {
      return this.httpClient.get<Crowdfund[]>(this.apiUrl+"/api/crowdfunds");
  }
  public getOneCrowdfund(id:Number){
    return this.httpClient.get<Crowdfund>(this.apiUrl+"/api/crowdfunds/"+id);
  }
}
