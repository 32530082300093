import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Product } from '../_models/products';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  apiUrl = environment.apiUrl

  private _products: BehaviorSubject<Product[] | null> = new BehaviorSubject(null)
  private _selectedProducts: BehaviorSubject<Product | null> = new BehaviorSubject(null)

  constructor(private _httpClient:HttpClient) { }


  get products$():Observable<Product[] | null>
  {
    return this._products.asObservable();
  }

  get selectedProducts$():Observable<Product>
  {
    return this._selectedProducts.asObservable();
  }



  getProducts():Observable<Product[]>{
    return this._httpClient.get<Product[]>(`${this.apiUrl}/api/products`).pipe(
      map((products) => {
          this._products.next(products)
          return products;
        }
      )
    )
  }

  addNewProduct(data: any): Observable<any>{
    return this.products$.pipe(
      take(1),
      switchMap((products) => this._httpClient.post<Product>(this.apiUrl+'/api/products', data, 
      ).pipe(
          map((newProduct: Product) => {
              this._products.next([newProduct, ...products])
              return newProduct;
          })
      ))
    );
  }

  deleteProduct(id: number): Observable<any>{
    return this.products$.pipe(
      take(1),
      switchMap((products) => this._httpClient.delete(this.apiUrl+'/api/products/'+id, 
      
      ).pipe(
          map((deleted: Product) => {
              const index = products.findIndex(x=>x.id == id);
              products.splice(index,1);
              this._products.next(products)
              return deleted;
          })
      ))
    );
  }


  getProduct(id:number): Observable<Product>{
    return this._httpClient.get<Product>(`${this.apiUrl}/api/products/`+id,
    ).pipe(
      map((product) => {
          this._selectedProducts.next(product)
          return product;
        }
      )
    )
  }


  updateProduct(id:number,data: any): Observable<Product>{
    return this.products$.pipe(
      take(1),
      switchMap((products) => this._httpClient.patch<Product>(this.apiUrl+'/api/products/'+ id, data, 
      ).pipe(
          map((updateProduct: Product) => {
            const priorityIndex = products.findIndex(d => d.id === updateProduct.id);
            if(priorityIndex > -1){
              products.splice(priorityIndex,1,updateProduct);
            }
            this._products.next(products);
              return updateProduct;
          })
      ))
    );
  }


}
