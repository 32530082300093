import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ProductsService } from '../_service/products.service';

@Component({
  selector: 'app-add-products',
  templateUrl: './add-products.component.html',
  styleUrls: ['./add-products.component.css']
})
export class AddProductsComponent implements OnInit {
  @ViewChild('storePriorityNgForm') addProductNgForm: NgForm;
   galleryfiles: Array<File> = [];

  addProductForm: FormGroup;
  thumbnail:File;
  constructor(
    private _formBuilder: FormBuilder,
    private _productService: ProductsService
    ) { }

  ngOnInit(): void {

    this.addProductForm = this._formBuilder.group({
      name: ['', Validators.required],
      description: [, Validators.required],
      price: [, Validators.required],
      sale_percentage: [, Validators.required],
      crowdfund_id: [0, Validators.required],
      thumbnail: [, Validators.required],
      gallery: [''],
  });

  }




  addProduct(){
    const formData  = new FormData();
    const result = Object.assign({}, this.addProductForm.value);
    formData.append('name', this.addProductForm.get('name').value);
    formData.append('description', this.addProductForm.get('description').value);
    formData.append('price', this.addProductForm.get('price').value);
    formData.append('sale_percentage', this.addProductForm.get('sale_percentage').value);
    formData.append('crowdfund_id', this.addProductForm.get('crowdfund_id').value);
    if(this.thumbnail instanceof File){
      formData.append('thumbnail', this.thumbnail);
    }else{

    }
    if(this.galleryfiles){
      this.galleryfiles.map((galleryfile,index)=>{
        formData.append(`gallery[${index}]`, galleryfile);
      })
    }

    this._productService.addNewProduct(formData).subscribe()

  }

  onFileChange(pFileList: File): void{
    console.log(pFileList);
    
    if (pFileList[0]) {
        if (
            pFileList[0].type === 'image/jpeg' ||
            pFileList[0].type === 'image/png' ||
            pFileList[0].type === 'image/jpg'
        ) {
          
            this.thumbnail = pFileList[0];
            const file = pFileList[0];
            this.addProductForm.patchValue({
              thumbnail: pFileList[0]
                });
           
        }else{
        }
    }
  }



  onFileChangeGallery(pFileList: File[]){
    if (pFileList) {
      debugger;
      Object.values(pFileList).forEach(file => {
        if (
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/jpg'
      ) {
        this.galleryfiles.push(file)
        
      }else{
      }
      });
      this.addProductForm.patchValue({
        gallery: this.galleryfiles
      });
      
  }
  
  }

}
