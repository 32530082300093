import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { ActivatedRoute, Params } from '@angular/router';
import {Product, ColorFilter, Produkti} from 'src/app/models/product.model';
import {CrowdfundService} from "../../shared/services/crowdfund.service";
import {Crowdfund} from "../../../models/Crowdfund.model";

@Component({
  selector: 'app-crowdfund',
  templateUrl: './crowdfund.component.html',
  styleUrls: ['./crowdfund.component.sass']
})
export class CrowdfundComponent implements OnInit {
  public animation    :   any;   // Animation
  public sortByOrder  :   string = '';   // sorting
  public page:any;
  public viewCol: number = 50;
  public crowdfunds: Crowdfund[] = [];

  constructor(private crowdfundService:CrowdfundService) {

  }

  ngOnInit() {
    this.crowdfundService.getAllCrowdfunds().subscribe(crowdfunds=>{
      this.crowdfunds=crowdfunds;
    })
  }

  public onPageChanged(event){
    this.page = event;
    window.scrollTo(0,0);
  }




}

