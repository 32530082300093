import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import { AuthUtils } from './auth.utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl = environment.apiUrl;
  private _authenticated: boolean = false;
  
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService
  ) { }

  get accessToken(): string
  {
      return localStorage.getItem('token') ?? '';
  }

  set accessToken(token: string)
  {
      localStorage.setItem('token', token);
  }

  signIn(credentials:any): Observable<any>
  {
      // Throw error, if the user is already logged in
      if ( this._authenticated )
      {
          return throwError('User is already logged in.');
      }

      return this._httpClient.post(this.apiUrl+'/api/login', credentials).pipe(
          switchMap((response: any) => {
              // Store the access token in the local storage
              this.accessToken = response.token;

              // Set the authenticated flag to true
              this._authenticated = true;

              // Store the user on the user service
              
                return of(response);
              // Return a new observable with the response
          })
      );
    }

    signup(credentials:any): Observable<any>
  {

      if ( this._authenticated )
      {
          return throwError('User is already logged in.');
      }
      return this._httpClient.post(this.apiUrl+'/api/register', credentials).pipe(
          switchMap((response: any) => {
              // Store the access token in the local storage
                this.accessToken = response.token;

                // Set the authenticated flag to true
                this._authenticated = true;
                return of(response);
              // Return a new observable with the response
          })
      );
    }

    signOut(): any
    {
       localStorage.removeItem('token');
       localStorage.removeItem('user_info');
       this._authenticated = false;
    }

    check(): Observable<boolean>
    {

        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }
        // Check the access token expire date
        return of(!AuthUtils.isTokenExpired(this.accessToken));
    }


}
