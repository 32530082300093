import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Product } from '../_models/products';
import { ProductsService } from '../_service/products.service';

@Component({
  selector: 'app-update-products',
  templateUrl: './update-products.component.html',
  styleUrls: ['./update-products.component.css']
})
export class UpdateProductsComponent implements OnInit {
  @ViewChild('storePriorityNgForm') addProductNgForm: NgForm;

  addProductForm: FormGroup;
  thumbnail:File;

  selectedProducts$ = this._productService.selectedProducts$;

  productId:Product;
  constructor(
    private _formBuilder: FormBuilder,
    private _productService: ProductsService,
    private route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    


  this._productService.getProduct(+this.route.snapshot.paramMap.get('id')).subscribe(res=>{
    this.productId = res
    this.addProductForm = this._formBuilder.group({
      name: [res.name, Validators.required],
      description: [res.description, Validators.required],
      price: [res.price, Validators.required],
      sale_percentage: [res.sale_percentage, Validators.required],
      crowdfund_id: [res.crowdfund_id, Validators.required],
      thumbnail: [res.thumbnail, Validators.required],
      gallery: [res.gallery],
  });
  })
  }
  
  onFileChange(pFileList: File): void{
    if (pFileList[0]) {
        if (
            pFileList[0].type === 'image/jpeg' ||
            pFileList[0].type === 'image/png' ||
            pFileList[0].type === 'image/jpg'
        ) {
           
            this.thumbnail = pFileList[0];
            const file = pFileList[0];
            this.addProductForm.patchValue({
              thumbnail: pFileList[0]
                });
           
        }else{
        }
    }
  }


  editProduct(){
    console.log(this.addProductForm.value);
    console.log(this.addProductForm.get('name').value);
    const formData  = new FormData();
    const result = Object.assign({}, this.addProductForm.value);
    formData.append('name', this.addProductForm.get('name').value);
    formData.append('description', this.addProductForm.get('description').value);
    formData.append('price', this.addProductForm.get('price').value);
    formData.append('sale_percentage', this.addProductForm.get('sale_percentage').value);
    formData.append('crowdfund_id', this.addProductForm.get('crowdfund_id').value);
    if(this.thumbnail instanceof File){
      formData.append('thumbnail', this.thumbnail);
    }else{

    }
    this._productService.updateProduct(this.productId.id,formData).subscribe()
  }
}
