import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './products.component';
import { Route, RouterModule } from '@angular/router';
import { AddProductsComponent } from './add-products/add-products.component';
import { UpdateProductsComponent } from './update-products/update-products.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';
import { SharedModule } from '../../components/shared/shared.module';


const routes: Route[] = [
  {
    path     : 'products',
    component: ProductsComponent,
    children:[
      {
        path:"",
        component: AddProductsComponent
      },
      {
        path:":id",
        component: UpdateProductsComponent
        
      }
    ]
    
  }
];

@NgModule({
  declarations: [
    ProductsComponent,
    AddProductsComponent,
    UpdateProductsComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule,
    ReactiveFormsModule,
  ]
})
export class ProductsModule { }
