import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {  Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './user.types';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    apiUrl = environment.apiUrl
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }
    
    set user(value: User)
    {
        console.log(value,"USERRRR");
        
        // Store the value
        this._user.next(value);
    }

    get(): Observable<User>
    {
        return this._httpClient.get(this.apiUrl + 'api/user').pipe(
            tap((user:any) => {
                this._user.next(user.data);
            }),
            
        );
    }

    update(user: User): Observable<any>
    {
        return this._httpClient.get(this.apiUrl + 'api/user').pipe(
            tap((user:any) => {
                this._user.next(user.data);
            })
        );
    }
}
