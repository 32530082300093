import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { Product } from 'src/app/models/product.model';
import {BrowserAnimationsModule, NoopAnimationsModule} from "@angular/platform-browser/animations";

@Component({
  selector: 'app-product-vertical',
  templateUrl: './product-vertical.component.html',
  styleUrls: ['./product-vertical.component.sass'],
  animations: [BrowserAnimationsModule,NoopAnimationsModule]
})
export class ProductVerticalComponent implements OnInit {

 @Input() products: Product[];

  constructor(private productService: ProductService ) { }

  ngOnInit() {
    alert("test")
    this.productService.getProducts()
    .subscribe (
    product => this.products = product
    )
    this.productService.getAllProducts()
      .subscribe (
        product =>{
          console.log("qetu123")
          console.log("--- "+product);
          console.log("1234")
        }
      )
  }

}
